<template>
	<div class="calendar">
		<div class="calendar-header">
			<div class="row">
				<h2 class="calendar-year">{{ currentYear }}</h2>
				<div class="buttons-holder">
					<button class="calendar-btn" @click="prevMonth">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M12.5 16.6004L7.0667 11.1671C6.42503 10.5254 6.42503 9.47539 7.0667 8.83372L12.5 3.40039" stroke="#1D1B20" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</button>
					<span class="calendar-month">{{ currentMonthLocale }}</span>
					<button class="calendar-btn" @click="nextMonth">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7.49997 3.39961L12.9333 8.83294C13.575 9.47461 13.575 10.5246 12.9333 11.1663L7.49997 16.5996" stroke="#1D1B20" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</button>
				</div>
			</div>
		</div>
		<div class="table-holder">
			<table class="table">
				<thead>
					<tr>
						<th v-for="day in weekDays" :key="day" class="p-2 border-r">{{ day }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(week, index) in weeks" :key="index">
						<td v-for="(day, dayIndex) in week" :key="dayIndex" :class="[day.isCurrentMonth ? 'current-month' : 'prev-month']">
							<div class="day-block" :class="day.isSameDay ? 'selected' : ''" @click="clickOnDay(day)">
								{{ day.date }}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="calendar-footer">
			<button class="calendar-button button-close" @click="closeCalendar">
				{{ $t("global.close") }}
			</button>
			<button class="calendar-button" @click="confirm">
				{{ $t("global.done") }}
			</button>
		</div>
	</div>
</template>

<script>
import { startOfWeek, endOfWeek, isSameMonth, startOfMonth, endOfMonth, format, eachDayOfInterval, isSameDay, subDays, addDays } from "date-fns";

export default {
	name: "Calendar",
	props: {
		date: {
			type: Date,
			default: () => new Date()
		},
		progress: {
			type: Array,
			default: () => []
		},
		deal: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			currentYear: null,
			currentMonth: "",
			weeks: [],
			weekDays: [this.$t("calendar.weekDay1"), this.$t("calendar.weekDay2"), this.$t("calendar.weekDay3"), this.$t("calendar.weekDay4"), this.$t("calendar.weekDay5"), this.$t("calendar.weekDay6"), this.$t("calendar.weekDay7")],
			currentMonthLocale: "",
			selectedDate: null
		};
	},
	methods: {
		prevMonth() {
			this.createCalendar(new Date(this.date.setMonth(this.date.getMonth() - 1)));
		},
		nextMonth() {
			this.createCalendar(new Date(this.date.setMonth(this.date.getMonth() + 1)));
		},
		createCalendar(date) {
			this.clearSelectedClass();
			this.selectedDate = date;
			this.currentMonth = format(date, "MMMM yyyy");
			this.currentYear = format(date, "yyyy");
			this.currentMonthLocale = date.toLocaleDateString(undefined, { month: "long" });

			const startOfMonthDate = startOfMonth(date);
			const endOfMonthDate = endOfMonth(date);
			const startOfWeekDate = startOfWeek(startOfMonthDate, {
				weekStartsOn: 1
			});
			const endOfWeekDate = endOfWeek(endOfMonthDate, { weekStartsOn: 1 });
			const days = eachDayOfInterval({
				start: startOfWeekDate,
				end: endOfWeekDate
			});

			const weeks = [];
			let week = [];
			let countWeekDay = 0;

			days.forEach((day) => {
				week.push({
					date: format(day, "dd") < 10 ? format(day, "dd")[1] : format(day, "dd"),
					isCurrentMonth: isSameMonth(day, startOfMonthDate),
					fullDate: format(day, "Y-MM-dd"),
					isSameDay: isSameDay(day, new Date())
				});

				countWeekDay++;

				if (countWeekDay === 7) {
					weeks.push(week);
					countWeekDay = 0;
					week = [];
				}
			});

			if (weeks.length < 6) {
				const daysFromNextMonth = eachDayOfInterval({
					start: addDays(endOfMonthDate, 1),
					end: addDays(endOfMonthDate, 7 - week.length)
				});

				daysFromNextMonth.forEach((day) => {
					week.push({
						date: format(day, "dd") < 10 ? format(day, "dd")[1] : format(day, "dd"),
						isCurrentMonth: false,
						fullDate: format(day, "Y-MM-dd"),
						isSameDay: isSameDay(day, new Date())
					});
				});

				weeks.push(week);
			}

			this.weeks = weeks;
		},
		clickOnDay(day) {
			this.selectedDate = day.fullDate;
			this.$emit("date-selected", this.selectedDate);

			this.clearSelectedClass();
			event.target.classList.add("selected");
		},
		closeCalendar() {
			this.$emit("onClose", this.selectedDate);
		},
		confirm() {
			this.$emit("onConfirm", this.selectedDate);
		},
		clearSelectedClass() {
			const dayBlocks = document.querySelectorAll(".day-block");

			dayBlocks.forEach((day) => {
				day.classList.remove("selected");
			});
		}
	},
	mounted() {
		this.createCalendar(this.date);
	}
};
</script>

<style lang="scss" scoped>
.calendar {
	color: #1d1b20;
	background: white;
	border-radius: 20px;

	.calendar-header {
		margin-bottom: 12px;
		padding: 20px 37px 16px 37px;
		border-bottom: solid 1px #e0ded8;

		.row {
			display: flex;
			justify-content: space-between;

			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}

		.days {
			color: rgba(29, 27, 32, 0.56);
		}

		.calendar-year {
			font-weight: 400;
			font-size: 24px;
			text-transform: uppercase;
		}

		.buttons-holder {
			display: flex;
			align-items: center;

			.calendar-month {
				min-width: 80px;
				margin: 0 15px;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: capitalize;
				text-align: center;
			}

			.calendar-btn {
				display: flex;
				background: transparent;
				border: none;
			}
		}
	}

	.table-holder {
		padding: 0 16px;
		min-height: 275px;
	}

	.table {
		width: 100%;
		font-size: 14px;
		text-align: center;
		user-select: none;

		th {
			padding: 5px 0;
			font-weight: 400;
			color: #aea8a8;
		}

		tbody {
			td {
				padding: 4px 0;
			}
		}

		.day-block {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			border-radius: 5px;

			&.selected {
				color: white;
				background: #1d1b20;
			}
		}

		.prev-month {
			color: #aea8a8;
			pointer-events: none;
		}
	}

	.calendar-footer {
		display: flex;
		justify-content: space-between;
		padding: 0 16px;
		border-top: solid 1px #e0ded8;

		.calendar-button {
			padding: 16px;
			font-size: 16px;
			background: transparent;
			border: none;

			&.button-close {
				color: #ff3b30;
			}
		}
	}
}
</style>
